@import "utilities/borders";

.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}

.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.x-action-button {
  @apply text-indigo-600
  hover:ring-2
  hover:ring-indigo-600
  px-2
  py-0.5
  rounded-lg
  border-2 border-indigo-100
  font-medium
  text-right text-xs;
}

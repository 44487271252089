.item {
  @apply border-b-2 p-2 hover:bg-zinc-200;
  &:last-child {
    @apply border-0;
  }
  .position {
    @apply text-slate-700 font-semibold text-base;
  }
  .company {
    @apply text-slate-600 font-medium text-sm;
  }
  .date {
    @apply text-slate-400 font-normal text-sm;
  }
  .location {
    @apply text-slate-400 font-normal text-sm;
  }
  .description {
    @apply text-slate-600 font-medium text-sm;
  }
}

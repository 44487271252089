.label {
  @apply text-slate-400 font-bold text-sm;
}

.value {
  @apply text-slate-700 font-medium text-sm;
}

.url {
  @apply text-blue-400 font-medium text-xs cursor-pointer;
}

.position {
  @apply text-slate-700 font-semibold text-base;
}

.company {
  @apply text-slate-600 font-medium text-sm;
}

.date {
  @apply text-slate-400 font-normal text-sm;
}

.location {
  @apply text-slate-400 font-normal text-sm;
}

.description {
  @apply text-slate-600 font-medium text-sm;

  ul,
  ol {
    padding-left: 15px;
  }

  li {
    list-style: none;
    position: relative;
  }

  li:before {
    position: absolute;
    z-index: 10;
    left: -10px;
    top: 9px;
    content: "·";
    font-size: 24px;
    line-height: 0;
  }
}
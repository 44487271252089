.summary {
  ul,
  ol {
    padding-left: 15px;
  }

  li {
    list-style: none;
    position: relative;
  }

  li:before {
    position: absolute;
    z-index: 10;
    left: -10px;
    top: 9px;
    content: "·";
    font-size: 24px;
    line-height: 0;    
  }
}

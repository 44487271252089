.card {
  @apply bg-stone-50 flex flex-col px-6 py-6 cursor-pointer hover:bg-stone-100 rounded-md shadow-md;

  .hr {
    @apply h-px my-2 bg-gray-200 border-0 dark:bg-gray-700;
  }

  .price {
    @apply mb-4 text-3xl font-extrabold text-slate-600 w1024:text-2xl;
  }
  .price_type {
    @apply my-1 text-xl font-bold text-slate-500 w1024:text-lg;
  }
  .price_type_description {
    @apply text-base italic text-slate-600 block min-h-60 w1024:min-h-100;
  }

  .credit {
    @apply my-2 text-2xl font-bold text-slate-600 w1024:text-xl;
  }
  .credit_description {
    @apply text-base text-slate-600;
  }

  .valid {
    @apply my-2 text-sm text-slate-500 font-medium;
  }
}

.label {
  @apply text-slate-400 font-bold text-sm;
}

.value {
  @apply text-slate-700 font-medium text-sm;
}

.url {
  @apply text-blue-400 font-medium text-xs cursor-pointer;
}

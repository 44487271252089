.row {
  @apply border-b-2 border-slate-200 cursor-pointer box-border;
}

.row:hover {
  @apply outline outline-slate-200 shadow-lg border-opacity-0 bg-zinc-100;
  .action {
    @apply flex items-center justify-end z-10;
  }
  .date,
  .tags {
    @apply hidden;
  }
}

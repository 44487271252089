// TODO: use @each
.debug-border-pink {
  border: 2px #db2777 solid;
}
.debug-border-green {
  border: 2px #16a34a solid;
}
.debug-border-blue {
  border: 2px #2563eb solid;
}
.debug-border-yellow {
  border: 2px #ca8a04 solid;
}
.debug-border-slate {
  border: 2px #475569 solid;
}
.debug-border-red {
  border: 2px #dc2626 solid;
}
